import { JSX } from "solid-js";
import c from "class-c";

import styles from "./Spinner.module.scss";

declare namespace Spinner {
  type Props = {
    class?: string;
    color?: string;
    size?: JSX.CSSProperties["width"];
    center?: boolean;
  };
}

function Spinner({
  class: className,
  color = "currentcolor",
  size,
  center,
}: D<Spinner.Props>) {
  return (
    <span
      class={c`
        ${c(styles)`
          spinner
          ${{ center }}
        `}
        ${center && "h100% center-child"}
        ${className}
      `}
      style={{ "font-size": size }}
    >
      <svg width="1em" height="1em">
        <g>
          <circle
            cx="0.5em"
            cy="0.5em"
            r="0.4em"
            fill="none"
            stroke-width="0.1em"
            style={{ stroke: color }}
          />
        </g>
      </svg>
    </span>
  );
}

declare namespace Spinner.Common {
  type Props = Spinner.Props;
  namespace WithMessage {
    type Props = Spinner.Common.Props & {
      message: string;
    };
  }
}

Spinner.Common = Object.assign(
  (props: Spinner.Common.Props) => <Spinner center size="24px" {...props} />,
  {
    WithMessage({ message, ...props }: D<Spinner.Common.WithMessage.Props>) {
      return (
        <div class="h100% center-child text-center">
          <div>
            <Spinner.Common {...props} />
            <div class="text:small mt-1.5">{message}</div>
          </div>
        </div>
      );
    },
  }
);

export default Spinner;
